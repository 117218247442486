import $ from 'jquery';
//import * as app from "../../../../../../public/static/prueba.json";
// Import y'all
//import { yall } from "yall-js";

// Invoke!
const isBrowser = typeof window !== "undefined"
//console.log = function () { };
import TagManager from 'react-gtm-module'


if (isBrowser) {


    $(document).ready(
        function () {

            const tagManagerArgs = {
                gtmId: 'GTM-M6W3BT6'
            }

            TagManager.initialize(tagManagerArgs)




            //   yall();

            //setTimeout(() => {
            console.log('redes')
            function noches_hospedajes() {
                var id = window.location.href

                var url = new URL(id);


                var fechaInicio = url.searchParams.get("fechaInicio");
                console.log(fechaInicio);
                var fechaFin = url.searchParams.get("fechaFin");
                console.log(fechaFin);
                var newFI = fechaInicio.replace(/-/g, '/')
                var newFF = fechaFin.replace(/-/g, '/')
                var dayInicio = new Date(newFI);
                var dayFin = new Date(newFF);
                console.log(dayInicio)
                console.log(dayFin)
                var fechaInicioR = newFI.split("/").reverse().join("/")
                var fechaFinR = newFF.split("/").reverse().join("/")
                var diff = dayFin - dayInicio;

                const weekday = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];

                const d = new Date();
                let day = weekday[d.getDay()];

                window.dataLayer.push({
                    'event': 'nochesHospedadas',
                    'startDate': fechaInicioR,
                    'endDate': fechaFinR,
                    'startDay': weekday[dayInicio.getDay()],
                    'endDay': weekday[dayFin.getDay()],
                    'qtyNight': diff / (1000 * 60 * 60 * 24),
                    'qtyHab': '1'
                })

            }
            function addToCart() {

                let id = window.location.href
                var url = new URL(id);
                console.log(url.pathname)
                var formData = []
                var arreglo = [];

                if (url.pathname.includes('reserva')) {
                    var datesCamping = document.querySelectorAll('#cantidadAdultoCamping');
                    console.log()

                    for (let index = 0; index < datesCamping.length; index++) {
                        console.log(datesCamping[index])
                        //console.log(dates[index].name)
                        if (datesCamping[index].attributes.name !== undefined) {
                            arreglo.push(parseInt(datesCamping[index].textContent.trim()))

                            var row = { id: datesCamping[index].getAttribute('data-sku'), nombre: datesCamping[index].getAttribute('data-nombre'), cantidad: datesCamping[index].textContent.trim() }
                            formData.push(row)
                        }
                    }
                    console.log(formData)

                    var datesCamping2 = document.querySelectorAll('#cantidadNinioCamping');


                    for (let index = 0; index < datesCamping2.length; index++) {
                        console.log(datesCamping2[index].attributes)
                        //console.log(dates[index].name)
                        if (datesCamping2[index].attributes.name !== undefined) {
                            var row = { id: datesCamping2[index].getAttribute('data-sku'), nombre: datesCamping2[index].getAttribute('data-nombre'), cantidad: datesCamping2[index].textContent.trim() }
                            if (datesCamping2[index].textContent.trim() !== "0") {
                                arreglo.push(parseInt(datesCamping2[index].textContent.trim()))

                                formData.push(row)
                            }

                        }
                    }
                    console.log(formData)


                    var dates = document.querySelectorAll('[id^=cantidadAdicional]');
                    console.log(dates)
                    for (let index = 0; index < dates.length; index++) {
                        //console.log(dates[index].attributes.name.nodeValue)
                        //console.log(dates[index].name)
                        if (dates[index].attributes.name !== undefined) {

                            arreglo.push(parseInt(dates[index].textContent))

                            var row = { id: dates[index].attributes.name.nodeValue, nombre: dates[index].getAttribute('data-nombre'), cantidad: dates[index].textContent }
                            formData.push(row)
                        }
                    }

                    var datesHabitacion = document.querySelectorAll('[id^=precioHabitacion]');
                    console.log(datesHabitacion)

                    for (let index = 0; index < datesHabitacion.length; index++) {
                        // console.log(dates[index].attributes.name.nodeValue)
                        // console.log(dates[index].name)

                        if (datesHabitacion[index].attributes.name !== undefined) {
                            if (datesHabitacion[index].attributes.name.nodeValue !== $('#precioHabitacionCamping').attr('name')) {
                                console.log('entro')

                                if (datesHabitacion[index].attributes.name.nodeValue == '344943' || datesHabitacion[index].attributes.name.nodeValue == '346246' || datesHabitacion[index].attributes.name.nodeValue == '353932' || datesHabitacion[index].attributes.name.nodeValue == '367955') {
                                    arreglo.push(parseInt(1))

                                    var row = { id: '000_G', nombre: datesHabitacion[index].getAttribute('data-nombre'), cantidad: 1 }
                                    formData.push(row)
                                } else {
                                    arreglo.push(parseInt(1))

                                    var row = { id: '000_H', nombre: datesHabitacion[index].getAttribute('data-nombre'), cantidad: 1 }
                                    formData.push(row)
                                }
                            }

                        }
                    }

                    console.log(JSON.stringify(formData, null, 4))


                } else {

                    var dates = document.querySelectorAll('*[id^="edit-field-"]');
                    console.log(dates)
                    /*var formData = {
                       entradaGeneral: $("#edit-field-entrada-general-und-0-value").val(),
                       entradaMenor: $("#edit-field-entrada-menor-und-0-value").val(), 
                     };*/

                    var formData = []
                    var arreglo = [];
                    for (let index = 0; index < dates.length; index++) {
                        console.log(dates[index])
                        //console.log(dates[index].name)
                        arreglo.push(parseInt(dates[index].value))
                        if (dates[index].value > 0) {
                            var row = { id: dates[index].getAttribute('data-sku'), nombre: dates[index].getAttribute('data-titulo'), cantidad: dates[index].value, precio: dates[index].getAttribute('data-precio') }
                            formData.push(row)
                        }

                    }

                }

                if ($("#idModal")) {


                    var cantidad = $("#edit-quantity2").val() > 0 ? $("#edit-quantity2").val() : $("#edit-quantity").val()
                    if (cantidad > 0) {
                        var modal = document.getElementById("myModal2");

                        formData = [{
                            id: $("#idModal").text(), nombre: $('#tituloModal2').text(), cantidad: cantidad,
                        }];

                        arreglo.push(parseInt(cantidad))

                        var datos = JSON.stringify(formData, 4, null)
                        console.log(datos)
                    }
                }

                const someIsNotZero = arreglo.some(item => item !== 0);
                const isAllZero = !someIsNotZero;
                const isAllZero2 = arreglo.every(item => item === 0);
                console.log(isAllZero)
                console.log(isAllZero2)

                var datos = JSON.stringify(formData, 4, null)
                console.log(datos)
                if (isAllZero == false) {
                    let id = window.location.href
                    var url = new URL(id);
                    var categoria = url.pathname
                    console.log(datos)
                    var cat = '';
                    var variante
                    var arrayCat = categoria.split('/')

                    if (categoria.includes('atracciones')) {
                        cat = 'atracciones'
                    }
                    if (categoria.includes('hospedaje')) {
                        cat = 'hospedaje'
                    }
                    if (categoria.includes('spa')) {
                        cat = 'spa'
                    }
                    if (categoria.includes('reserva')) {
                        cat = 'reserva'
                    }
                    console.log(formData)
                    for (let index = 0; index < formData.length; index++) {

                        if (formData[index].nombre.includes('1.25')) {
                            variante = 'menores de 1.25m'
                        }
                        if (formData[index].nombre.includes('90')) {
                            variante = 'menores de 90cm'
                        }
                        if (formData[index].nombre.includes('menor')) {
                            variante = 'menores'
                        }
                        if (!formData[index].nombre.includes('1.25')) {
                            variante = 'general'
                        }
                        console.log(formData)
                        /*window.dataLayer.push({
                            'event': 'addToCart', 'producto': {
                                'item': formData[index].nombre,
                                'categoria': cat,
                                'qty': formData[index].cantidad,
                                'variante': variante,
                            }
                        });*/
                        // window.dataLayer.push({ 'ecommerce': null })
                        window.dataLayer.push({
                            'event': 'event',
                            'brand': formData[index].nombre,
                            'category': cat,
                            'item': formData[index].nombre,
                            'variants': variante,
                            'qty': formData[index].cantidad,
                            ecommerce: {
                                items: [{                        //  adding a product to a shopping cart.
                                    item_name: formData[index].nombre,
                                    item_id: formData[index].id,
                                    price: formData[index].precio,
                                    item_brand: formData[index].nombre,
                                    item_category: cat,
                                    item_variant: variante,
                                    item_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                    item_list_id: "lasEstacas",
                                    index: 1,
                                    quantity: formData[index].cantidad
                                }]
                            }

                        });
                    }
                }
            }
            function addNewsletter() {
                window.dataLayer.push({
                    'event': 'addNewsletter',
                    'nombre': $('#edit-field-nombre-und-0-value--2').val(),
                    'correo': $('#edit-field-email-und-0-email').val(),
                    'fechaNacimiento': $('#fecha').val()
                })
            }

            function addContacto() {
                window.dataLayer.push({
                    'event': 'addContacto',
                    'nombre': $('#nombreCompleto').val(),
                    'telefono': $('#telefono').val(),
                    'correo': $('#email').val(),
                    'mensaje': $('#comentario').val()

                })
            }


            document.addEventListener('click', function (e) {
                console.log(e.target.id)
                var id = e.target.id

                if (e.target && id.includes('siguiente3')) {
                    console.log('clic gtm')
                    addToCart()
                    noches_hospedajes()
                } if (e.target && id.includes('sendMail')) {
                    addNewsletter()
                }
                if (e.target && id.includes('enviarFormulario')) {
                    addContacto()
                }
            })
            /*
                window.addEventListener("load", function() {
                    console.log('carga de evento')
                    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy"));
                  
                    if ("IntersectionObserver" in window) {
                      let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
                        entries.forEach(function(entry) {
                          if (entry.isIntersecting) {
                            entry.target.classList.add("visible");
                            lazyBackgroundObserver.unobserve(entry.target);
                          }
                        });
                      });
                  
                      lazyBackgrounds.forEach(function(lazyBackground) {
                        lazyBackgroundObserver.observe(lazyBackground);
                      });
                    }
                  })
                */



            window.addEventListener("load", function () {
                setTimeout(() => {
                    /*console.log('evento cargado')
                    var ll = $('.lazy');
                    var lh = []
                    var wscroll = 0;
                    var wh = $(window).height();
                    
                    function update_offsets(){
                      $('.lazy').each(function(){
                        var x = $(this).offset().top;
                        lh.push(x);
                      });
                    };
                    
                    function lazy() {
                      wscroll = $(window).scrollTop();
                      for(var i = 0; i < lh.length; i++){
                        if(lh[i] <= wscroll + (wh - 200)){
                          $('.lazy').eq(i).addClass('loaded');
                        };
                      };
                    };
                    
                    // Page Load
                    update_offsets();
                    lazy();
                    
                    $(window).on('scroll',function(){
                      lazy();
                    });
                    */

                    console.log('se cargo el evento')
                    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy"));
                    console.log('eventos ', lazyBackgrounds)
                    if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
                        let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
                            entries.forEach(function (entry) {
                                if (entry.isIntersecting) {
                                    entry.target.classList.add("visible");
                                    lazyBackgroundObserver.unobserve(entry.target);
                                }
                            });
                        });

                        lazyBackgrounds.forEach(function (lazyBackground) {
                            lazyBackgroundObserver.observe(lazyBackground);
                        });
                    }


                }, 1000);

            });

            /*
              window.addEventListener("load", function() {
                console.log('evento cargado')
                var lazyloadImages;    
              setTimeout(() => {
                  
              
                if ("IntersectionObserver" in window) {
                    console.log('entro 1')
                  lazyloadImages = document.querySelectorAll(".lazy");
                  console.log(lazyloadImages)
              
                  var imageObserver = new IntersectionObserver(function(entries, observer) {
                    entries.forEach(function(entry) {
                      if (entry.isIntersecting) {
                        var image = entry.target;
                        image.classList.remove("lazy");
                        imageObserver.unobserve(image);
                      }
                    });
                  });
              
                  lazyloadImages.forEach(function(image) {
                    imageObserver.observe(image);
                  });
                } else {  
                    console.log('entro dos')
                  var lazyloadThrottleTimeout;
                  lazyloadImages = document.querySelectorAll(".lazy");
                  console.log(lazyloadImages)
                  function lazyload () {
                    if(lazyloadThrottleTimeout) {
                      clearTimeout(lazyloadThrottleTimeout);
                    }    
              
                    lazyloadThrottleTimeout = setTimeout(function() {
                      var scrollTop = window.pageYOffset;
                      lazyloadImages.forEach(function(img) {
                          if(img.offsetTop < (window.innerHeight + scrollTop)) {
                            img.src = img.dataset.src;
                            img.classList.remove('lazy');
                          }
                      });
                      if(lazyloadImages.length == 0) { 
                        document.removeEventListener("scroll", lazyload);
                        window.removeEventListener("resize", lazyload);
                        window.removeEventListener("orientationChange", lazyload);
                      }
                    }, 20);
                  }
              
                  document.addEventListener("scroll", lazyload);
                  window.addEventListener("resize", lazyload);
                  window.addEventListener("orientationChange", lazyload);
                }
            }, 100);
              })*/
            //}, 5000);
            //}, 3000);  
            // console.log(app[0])
            /* $('meta[name=title]').attr('content', app[0].nombre);
             $('meta[name=descripcion]').attr('content', app[0].descripcion);
 */

            console.log($('meta[name=title]'))
            /* console.log(window.location.pathname)
             let id = window.location.pathname
             id = id.substring(1);
     if (id.charAt(id.length-1)=="/") {
       id=id.slice(0,-1)
     }
     console.log(id)
     var data={'path':id}
                 console.log(JSON.stringify( data,null,4))
                 console.log(id)
             fetch("https://new.lasestacas.com/node-api/m22_get_content_node/",{
               method:'POST',
               headers:{'Content-type':'application/json'},
               body:JSON.stringify( data,null,4)
             })
                   .then(response => response.json()) // parse JSON from request
                   .then(resultData => {
                     console.log(resultData)
                    
                  
                   })
             console.log('cargado')
     
     
             $.ajax({
                 url:"https://new.lasestacas.com/node-api/m22_get_content_node/",
                 method:"POST", //First change type to method here
                 contentType:"application/json",
                 dataType:"JSON",
                 data:JSON.stringify( data,null,4),
                 success:function(response) {
                     console.log(response)
                     //$('meta[name=description]').attr('content', response.field_descripcion_sitio.und[0].value);
                     //$('meta[name=title]').attr('content', response.title);
     
                 },
                error:function(){
                 //alert("error");
                }
         
               });
     
               fetch("../prueba.json")
               .then(response => response.json())
               .then(json => console.log(json));
     
     */

            /*  $.getJSON( "../../../../static/prueba.json", function( data ) {
                console.log(data)
                document.title=data[0].nombre
                  $('meta[name=title]').attr('content', data[0].nombre);
                    $('meta[name=description]').attr('content', data[0].descripcion);
              });
    */
            setTimeout(() => {



                /*  window.dataLayer = window.dataLayer || [];
                  function gtag() { dataLayer.push(arguments); }
                  gtag('js', new Date());
  
                  gtag('config', 'G-Z6WYHZQ04M');*/
                console.log('cargado para clic')

                window.dataLayer = window.dataLayer || [];
                /*function gtag() {
                    window.dataLayer.push(arguments);
                }
                gtag('js', new Date());*/
                //gtag('config', 'UA-115473975-1');
                //window.dataLayer.push('config', 'G-Z6WYHZQ04M');



                /*const btn = document.getElementById('edit-submit--4');
                btn.addEventListener('click', function(event){
                    console.log('clic edit')
                        window.dataLayer.push({
                            'event':'event',
                            'eventCategory':'Clic en como llegar',
                            'eventAction':'prueba',
                            'eventLabel':''
                        });
                });*/
                //$().once('gtmSubmitComoLlegar',function(){
                /* $('#edit-submit--4').bind('click').on('click',function(){
                     console.log('clic edit')
                     window.dataLayer.push({
                         'event':'event',
                         'eventCategory':'Clic en como llegar',
                         'eventAction':'prueba',
                         'eventLabel':''
                     });
                 });*/
                /*
                    $( "#edit-submit--4" ).bind({
                        click: function() {
                            console.log('clic edit')
                            window.dataLayer.push({
                                'event':'event',
                                'eventCategory':'Clic en como llegar',
                                'eventAction':'prueba',
                                'eventLabel':''
                            });
                        }
                      });*/
                //});






                $("#agregarCarrito").click(function () {
                    console.log('clic edit')
                    /*window.dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':'prueba',
                        'eventLabel':''
                    });*/
                    addToCart();

                });
                $("#agregarCarritoCamping").click(function () {
                    console.log('clic edit camping')
                    /*window.dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':'prueba',
                        'eventLabel':''
                    });*/
                    addToCart();

                });

                $("#agregarCarritoComplementa").click(function () {
                    console.log('clic edit complementa')
                    /*window.dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':'prueba',
                        'eventLabel':''
                    });*/
                    addToCart();

                });



                $("#formularioResumen").submit(function () {
                    addToCart();

                    noches_hospedajes();
                })

                $("#siguiente3").click(function () {
                    var formData = []
                    var arreglo = [];
                    console.log('clic edit reserva')
                    /*window.dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':'prueba',
                        'eventLabel':''
                    });*/
                    addToCart();

                    noches_hospedajes();


                });


                $(".sendMail").click(function () {
                    console.log('clic edit camping')
                    /*window.dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':'prueba',
                        'eventLabel':''
                    });*/
                    addNewsletter();

                });
                $(".enviarFormulario").click(function () {
                    console.log('clic edit camping')
                    /*window.dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':'prueba',
                        'eventLabel':''
                    });*/
                    addContacto();

                });














                /*$("#siguiente3").click(function () {
                    
                 var datesCamping = document.querySelectorAll('#cantidadAdultoCamping');
                 console.log()
                
                 for (let index = 0; index < datesCamping.length; index++) {
                   console.log(datesCamping[index].attributes)
                   //console.log(dates[index].name)
                   if (datesCamping[index].attributes.name!==undefined) {
                   var row ={id:datesCamping[index].attributes.name.nodeValue,cantidad:datesCamping[index].textContent.trim(),idReserva:idReservaHotel===undefined?idReservaGlamping:idReservaHotel,}
                   formData.push(row)
                   }
                }
                console.log(formData)
                
                var datesCamping2 = document.querySelectorAll('#cantidadNinioCamping');
                
                
                 for (let index = 0; index < datesCamping2.length; index++) {
                   console.log(datesCamping2[index].attributes.name.nodeValue)
                   //console.log(dates[index].name)
                   if (datesCamping2[index].attributes.name!==undefined) {
                   var row ={id:datesCamping2[index].attributes.name.nodeValue,nombre:'',cantidad:datesCamping2[index].textContent.trim(),idReserva:idReservaHotel===undefined?idReservaGlamping:idReservaHotel,fechaEntrada:'',fechaSalida:''}
                   if (datesCamping2[index].textContent.trim()!==0) {
                    formData.push(row)
                   }
                   
                   }
                }
                console.log(formData)
                
                
                var dates = document.querySelectorAll('[id^=cantidadAdicional]');
                console.log(dates)
                for (let index = 0; index < dates.length; index++) {
                //console.log(dates[index].attributes.name.nodeValue)
                //console.log(dates[index].name)
                if (dates[index].attributes.name!==undefined) {
                
                
                var row ={id:dates[index].attributes.name.nodeValue,cantidad:dates[index].textContent,idReserva:idReservaHotel===undefined?idReservaGlamping:idReservaHotel}
                formData.push(row)
                }
                }
                
                var datesHabitacion = document.querySelectorAll('[id^=precioHabitacion]');
                 console.log($('#precioHabitacionCamping').attr('name'))
                
                 for (let index = 0; index < datesHabitacion.length; index++) {
                  // console.log(dates[index].attributes.name.nodeValue)
                  // console.log(dates[index].name)
                   if (datesHabitacion[index].attributes.name!==undefined) {
                      if (datesHabitacion[index].attributes.name.nodeValue!==$('#precioHabitacionCamping').attr('name')) {
                         console.log('entro')
                      
                      if (datesHabitacion[index].attributes.name.nodeValue=='344943'||datesHabitacion[index].attributes.name.nodeValue=='346246'||datesHabitacion[index].attributes.name.nodeValue=='353932'||datesHabitacion[index].attributes.name.nodeValue=='367955') {
                         var row ={id:'000_G',cantidad:1,idReserva:idReservaGlamping}
                   formData.push(row)
                      }else{
                         var row ={id:'000_H',cantidad:1,idReserva:idReservaHotel}
                         formData.push(row)
                      }
                    }
                   
                   }
                }
                
                console.log(JSON.stringify(formData, null, 4))
                
                
                addToCart();
                })*/


            }, 3000);

            var id = window.location.href

            var url = new URL(id);
            if (url.pathname.includes('reserva')) {

                setTimeout(() => {


                    $(document).ready(function () {
                        var formData = [];
                        var arreglo = []
                        arreglo.push(1)
                        var formulario = document.querySelectorAll('#productos');
                        for (let index = 0; index < formulario.length; index++) {

                            var row = { nombre: formulario[index].getAttribute('data-nombre'), comp: formulario[index].getAttribute('data-componente'), sku: formulario[index].getAttribute('data-sku'), precio: formulario[index].getAttribute('data-precio') }
                            formData.push(row)

                        }
                        console.log(formData)


                        const someIsNotZero = arreglo.some(item => item !== 0);
                        const isAllZero = !someIsNotZero;
                        const isAllZero2 = arreglo.every(item => item === 0);
                        console.log(isAllZero)
                        console.log(isAllZero2)

                        var datos = JSON.stringify(formData, 4, null)
                        console.log(datos)
                        // if (isAllZero==false) {
                        let id = window.location.href
                        var url = new URL(id);
                        var categoria = url.pathname
                        console.log(categoria)
                        var cat = '';
                        var variante
                        var arrayCat = categoria.split('/')

                        if (categoria.includes('atracciones')) {
                            cat = 'atracciones'
                        }
                        if (categoria.includes('hospedaje')) {
                            cat = 'hospedaje'
                        }
                        if (categoria.includes('spa')) {
                            cat = 'spa'
                        }
                        if (categoria.includes('reserva')) {
                            cat = 'hospedaje'
                        }
                        console.log(formData.length)
                        for (let index = 0; index < formData.length; index++) {

                            if (formData[index].nombre.includes('1.25')) {
                                variante = 'menores de 1.25m'
                            }
                            if (formData[index].nombre.includes('90')) {
                                variante = 'menores de 90cm'
                            }
                            if (formData[index].nombre.includes('menor') || formData[index].nombre.includes('Menor')) {
                                variante = 'menores'
                            }
                            if (!formData[index].nombre.includes('1.25')) {
                                variante = 'general'
                            }

                            window.dataLayer.push({
                                'event': 'productImpressions',
                                'item': formData[index].nombre,
                                'category': cat,
                                'brand': formData[index].nombre,
                                'variants': variante,
                                'url': categoria,
                                'lista': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                ecommerce: {
                                    items:
                                        [
                                            {
                                                item_name: formData[index].nombre,
                                                item_id: formData[index].sku,
                                                price: formData[index].precio,
                                                item_brand: formData[index].nombre,
                                                item_category: cat,
                                                item_variant: variante,
                                                tiem_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                                index: '1',
                                                quantity: '1'
                                            }]
                                }
                            })
                            /*
                            window.dataLayer.push('event', 'comprar', {
                                'item': formData[index].nombre,
                                'categoria': cat,
                                'variante': variante,
                                'id': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp
                            });
        
                            dataLayer.push({
                                'ecommerce': {
                                    'currencyCode': 'EUR',                       // Local currency is optional.
                                    'impressions': [
                                        {
                                            'name': formData[index].nombre,       // Name or ID is required.
                                            'id': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                            'price': '15.25',
                                            'brand': 'Las estacas',
                                            'category': cat,
                                            'variant': variante,
                                            'list': 'Search Results',
                                            'position': 1
                                        }]
                                }
                            });*/
                        }
                        //      }
                    })

                }, 10000);
            } else {
                setTimeout(() => {


                    $(document).ready(function () {
                        var formData = [];
                        var arreglo = []
                        arreglo.push(1)
                        var formulario = document.querySelectorAll('#productos');
                        for (let index = 0; index < formulario.length; index++) {

                            var row = { nombre: formulario[index].textContent.replace('-+', '').trim(), comp: formulario[index].getAttribute('data-componente'), sku: formulario[index].getAttribute('data-sku'), precio: formulario[index].getAttribute('data-precio') }
                            formData.push(row)

                        }
                        console.log(formData)


                        const someIsNotZero = arreglo.some(item => item !== 0);
                        const isAllZero = !someIsNotZero;
                        const isAllZero2 = arreglo.every(item => item === 0);
                        console.log(isAllZero)
                        console.log(isAllZero2)

                        var datos = JSON.stringify(formData, 4, null)
                        console.log(datos)
                        // if (isAllZero==false) {
                        let id = window.location.href
                        var url = new URL(id);
                        var categoria = url.pathname
                        console.log(categoria)
                        var cat = '';
                        var variante
                        var arrayCat = categoria.split('/')

                        if (categoria.includes('atracciones')) {
                            cat = 'atracciones'
                        }
                        if (categoria.includes('hospedaje')) {
                            cat = 'hospedaje'
                        }
                        if (categoria.includes('spa')) {
                            cat = 'spa'
                        }
                        if (categoria.includes('reserva')) {
                            cat = 'hospedaje'
                        }
                        console.log(formData.length)
                        for (let index = 0; index < formData.length; index++) {

                            if (formData[index].nombre.includes('1.25')) {
                                variante = 'menores de 1.25m'
                            }
                            if (formData[index].nombre.includes('90')) {
                                variante = 'menores de 90cm'
                            }
                            if (formData[index].nombre.includes('menor') || formData[index].nombre.includes('Menor')) {
                                variante = 'menores'
                            }
                            if (!formData[index].nombre.includes('1.25')) {
                                variante = 'general'
                            }

                            window.dataLayer.push({
                                'event': 'productImpressions',
                                'item': formData[index].nombre,
                                'category': cat,
                                'brand': formData[index].nombre,
                                'variants': variante,
                                'url': categoria,
                                'lista': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                ecommerce: {
                                    items:
                                        [
                                            {
                                                item_name: formData[index].nombre,
                                                item_id: formData[index].sku,
                                                price: formData[index].precio,
                                                item_brand: formData[index].nombre,
                                                item_category: cat,
                                                item_variant: variante,
                                                tiem_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                                index: '1',
                                                quantity: '1'
                                            }]
                                }
                            })
                            /*
                            window.dataLayer.push('event', 'comprar', {
                                'item': formData[index].nombre,
                                'categoria': cat,
                                'variante': variante,
                                'id': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp
                            });
        
                            dataLayer.push({
                                'ecommerce': {
                                    'currencyCode': 'EUR',                       // Local currency is optional.
                                    'impressions': [
                                        {
                                            'name': formData[index].nombre,       // Name or ID is required.
                                            'id': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                            'price': '15.25',
                                            'brand': 'Las estacas',
                                            'category': cat,
                                            'variant': variante,
                                            'list': 'Search Results',
                                            'position': 1
                                        }]
                                }
                            });*/
                        }
                        //      }
                    })

                }, 3000);
            }



            /*
            $('div.como-llegar-directions form').once('gtmSubmitComoLlegar',function(){
                $(this).on('submit',function(){
                    dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en como llegar',
                        'eventAction':$('div.como-llegar-directions form #ubicacion').val(),
                        'eventLabel':''
                    });
                });
            });
    
            $('div.footer form.entitytype-suscripcion_a_lista_de_correo-form').once('gtmSubmitNL',function(){
                $(this).on('submit',function(){
                  dataLayer.push({
                      'event':'event',
                      'eventCategory':'Suscripción NL',
                      'eventAction':window.location.pathname,
                      'eventLabel':''
                  });
                });
            });
    
            $('div.view-tk-events-list div.event-features.views-fieldset div.views-field div.event-tickets').once('gtmQuieroBoletos',function(){
                $(this).on('mousedown',function(){
                    dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en eventos',
                        'eventAction':$(this).parents('div.event-features.views-fieldset').siblings('div.views-field.views-field-field-tk-front-image').find('img').attr('title'),
                        'eventLabel':'Quiero boletos'
                    });
                });
            });
    
            $('div.view-tk-events-list div.event-features.views-fieldset div span.microsite-link').once('gtmQuieroInfo',function(){
                $(this).on('mousedown',function(){
                    dataLayer.push({
                        'event':'event',
                        'eventCategory':'Clic en eventos',
                        'eventAction':$(this).parents('div.event-features.views-fieldset').siblings('div.views-field.views-field-field-tk-front-image').find('img').attr('title'),
                        'eventLabel':'Más Info'
                    });
                });
            });
    
    
            function getProductImpressionData(mode){
                var indexAct = 0;
                var brand = $('div.cart-event-title h1').text();
                var impressions = [];
                var checkoutProducts = [];
                var variant = "";
                $('div.view-tk-event-tickets.view-id-tk_event_tickets #views-form-tk-event-tickets-page > div').children('div').each(function(index){
                    indexAct++;
                    if($(this).hasClass('discounts')){
                        $(this).children('div').each(function(i){
                            indexAct = i+indexAct;
                            var position = indexAct;
                            var productId = $(this).children('h4.cart-ticket-title').children('div').attr('data-product-sku');
                            //console.log(indexAct);
                            var productName = $(this).children('h4.cart-ticket-title').children('div').text();
                            var productPrice = $(this).children('div.cart-price-product').children('div').text();
                            productPrice = productPrice.replace('$','');
                            if(mode === 'productImpression'){
                                impressions.push({
                                    "id":productId,    //internal id or SKU
                                    "name":productName,    //you should have at least Name and ID set up
                                    "price":productPrice,   //use only xxxx.xx formatting
                                    "brand":brand, //manufacturer or designer
                                    "position":position,
                                    "variant":variant //position within the list
                                });
                            }
                            else{
                                var productQuantity = $(this).children('div.cart-product-quantity').children('span').children('div.form-type-select').children('select.form-select').val();
                                if(productQuantity > 0){
                                    checkoutProducts.push({ "id":productId,
                                        "name":productName,
                                        "price":productPrice,
                                        "brand":brand,
                                        "variant":variant, //position within the list
                                        "position":position, //it could prove insightful to send the product position within the cart in the checkout process, although not mandatory
                                        "quantity":productQuantity
                                    });
                                }
                            }
                        });
                    }
                    else{
                        var productName = $(this).children('h4.cart-ticket-title').children('div').text();
                        var productPrice = $(this).children('div.cart-price-product').children('div').text();
                        productPrice = productPrice.replace('$','');
                        var position = indexAct;
                        var productId = $(this).children('h4.cart-ticket-title').children('div').attr('data-product-sku');
                        variant = productName;
                        if(mode === 'productImpression'){
                            impressions.push({
                                "id":productId,    //internal id or SKU
                                "name":productName,    //you should have at least Name and ID set up
                                "price":productPrice,   //use only xxxx.xx formatting
                                "brand":brand, //manufacturer or designer
                                "position":position,    //position within the list
                            });
                        }
                        else{
                            var productQuantity = $(this).children('div.cart-product-quantity').children('span').children('div.form-type-select').children('select.form-select').val();
                            if(productQuantity > 0){
                                checkoutProducts.push({ "id":productId,
                                    "name":productName,
                                    "price":productPrice,
                                    "brand":brand,
                                    "position":position, //it could prove insightful to send the product position within the cart in the checkout process, although not mandatory
                                    "quantity":productQuantity
                                });
                            }
                        }
                    }
                });
                if(mode === "productImpression"){
                    dataLayer.push({
                        "event":"productImpression",
                        "ecommerce":{
                            "currencyCode":'MXN',
                            "impressions":impressions
                        }
                    });
                }
                else{
    
                    dataLayer.push({
                        'ecommerce' : { 'click' :
                                { 'products' : checkoutProducts,
                                    'actionField' : {'list' : 'productClick' }
                                }
                        },
                        'event' : 'productClick'
                    });
    
                    dataLayer.push({
                        "event":"addToCart",
                        "ecommerce":{
                            "currencyCode":"MXN",
                            "add":{"products": checkoutProducts}
                        }
                    });
    
                    var ecommerceObject = {
                        'ecommerce' : { 'checkout' :
                                {
                                    'products' : checkoutProducts,
                                    'actionField' : {'step' : 1 }
                                }
                        },
                        'event' : 'addToCart'
                    };
                    // console.log(ecommerceObject);
                    dataLayer.push(ecommerceObject);
                    // console.log(dataLayer);
                }
                
    
            }
    
                if($('body.section-eventos').length === 1) {
                    $(document).ready(function () {
                        if ($('div.view-tk-event-tickets.view-id-tk_event_tickets #views-form-tk-event-tickets-page').length === 1) {
                            getProductImpressionData('productImpression');
                        }
                    });
                }
    
                $('div.view-tk-event-tickets.view-id-tk_event_tickets #views-form-tk-event-tickets-page fieldset.checkout-buttons.form-wrapper').children('div.fieldset-wrapper').children('button.form-submit').once('gtmSubmitForm',function(){
                    $(this).on('mousedown',function(){
                        getProductImpressionData();
                    });
                });
    
                if($(window).width() < 768){
                $('div.row button[name="waze"]').once('GTMWaze',function () {
                    $(this).on('mousedown',function(){
                       dataLayer.push({
                           'event':'event',
                           'eventCategory':'Clics de contacto',
                           'eventAction':'Waze',
                           'eventLabel':window.location.pathname
                       });
                    });
                });
    
                $('div.row button[name="maps"]').once('GTMWaze',function () {
                    $(this).on('mousedown',function(){
                        dataLayer.push({
                            'event':'event',
                            'eventCategory':'Clics de contacto',
                            'eventAction':'GoogleMaps',
                            'eventLabel':window.location.pathname
                        });
                    });
                });
    
                $('div.row button[name="celular"]').once('GTMWaze',function () {
                    $(this).on('mousedown',function(){
                        dataLayer.push({
                            'event':'event',
                            'eventCategory':'Clics de contacto',
                            'eventAction':'Teléfono',
                            'eventLabel':window.location.pathname
                        });
                    });
                });
    
                $('div.row button[name="whatsapp"]').once('GTMWaze',function () {
                    $(this).on('mousedown',function(){
                        dataLayer.push({
                            'event':'event',
                            'eventCategory':'Clics de contacto',
                            'eventAction':'WhatsApp',
                            'eventLabel':window.location.pathname
                        });
                    });
                });
            }
    
           */

        });
}